.navbar-brand img {
    max-width: 10%;
    height: auto;
  }
  
  @media (min-width: 768px) {
    .navbar-brand img {
      width: 50%;
      height: auto;
      max-width: none; /* Resetting max-width if previously set */
    }
  }
  